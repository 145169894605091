import React, { useState, useContext, useEffect } from "react";
import { generateContent, saveDescriptionData, fetchQueryData } from "./DescriptionsUtility";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Tab, Nav, Card } from 'react-bootstrap';
import { usePopup } from "../../context/PopupContext.js";
import { CategoryDivider } from "../visual/headers.js";
import { AuthContext } from "../../context/AuthContext";

// services
import { fetchDescriptionForSku } from "../../services/descriptionServices";


const Descriptions = ({ sku, data, saveDescriptionData }) => {
  const [activeKey, setActiveKey] = useState('amazon-fr');
  const [localData, setLocalData] = useState({});


  return (
    <div>
      <h1>Descriptions</h1>
    </div>
  );

};

export default Descriptions;