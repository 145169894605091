import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../setup/axiosInstance.js";
import { ListManager } from "react-beautiful-dnd-grid";
import UseWindowSize from "./UseWindowSize";
import { ButtonDelete } from "./Buttons.js";
import { PopUpMessages } from "./PopUpMessages.js";
import DeleteImage from "./DeleteImages.js";
import { usePopup } from '../context/PopupContext.js';
import proxy from '../setup/proxy.js';



function ProductImagesToSort({ selectedSKU }) {

 const { showPopupSmall } = usePopup();
  const [images, setImages] = useState([]);
  const [skus, setSkus] = useState([]);
  const [filename, setFilename] = useState([]);
  const [fileDeletionFlag, setFileDeletionFlag] = useState(false);
 


  const imgSize = 250;
  const dynamicUrl = `${proxy}/img/dynamic/${imgSize}/`;
  const serverUrl = `${proxy}/content/`;
  const [showPopup, setShowPopup] = useState(false);

  let { width, height } = UseWindowSize();
  let dragWidth = Math.floor(width / 12) * 8 - 50;
  let tileWidth = Math.floor(dragWidth / 5);

  const fetchImages = useCallback(async () => {
    if (selectedSKU) {
      try {
        const response = await axiosInstance.get(
          `${proxy}/img/get-all/${selectedSKU}`
        );
        const sortedImages = response.data.sort(
          (a, b) => a.visual_order - b.visual_order
        );

        setImages(sortedImages);
      } catch (error) {
        console.error("Error fetching images:", error);
        setImages([]);
      }
    }
  }, [selectedSKU]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages, fileDeletionFlag]);

  
  // handeling delete button
  const handleDelete = async (filename) => {
    try {
      const response = await axiosInstance.post(
        `${proxy}/img/get-skus-for-filename`,
        {
          filename: filename,
        }
      );
      setSkus(response.data);
      console.log("SKUs:", skus);
      setFilename(filename);
      setShowPopup(true);
    } catch (error) {
      console.error("Error fetching SKUs:", error);
    }
  };

  const confirmDelete = async () => {
    try {
       const deleteObject = await DeleteImage(filename); // Zakładamy, że funkcja rzuca błąd w przypadku niepowodzenia
      //  const newImages = images.filter((img) => img.filename !== filename);// Usunięcie obrazu ze stanu
      //  setImages(newImages);

       if(deleteObject.request.status === 200){
        setFileDeletionFlag(!fileDeletionFlag);
        showPopupSmall("file deleted successfuly", "success");
       } else {
        console.log("Error deleting image: server error kurwa");
        showPopupSmall(JSON.stringify(deleteObject), "error");
       }
    } catch (error) {
      console.log("Error deleting image:", error);
      
      showPopupSmall(JSON.stringify(error), "error");
    }
    finally{
      setShowPopup(false);
    }
  };

  const cancelDelete = () => {
    console.log("Cancelled deletion");
    setShowPopup(false);
  };

  const onDragEnd = (sourceIndex, destinationIndex) => {
    console.log(
      "Source Index:",
      sourceIndex,
      "Destination Index:",
      destinationIndex
    );
    if (destinationIndex === undefined) {
      return; // Dropped outside the list
    }

    const items = Array.from(images);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    // Aktualizacja visual_order
    const updatedItems = items.map((item, index) => ({
      ...item,
      visual_order: index + 1, // Zakładamy, że visual_order zaczyna się od 1
    }));

    setImages(updatedItems);
  };

  const saveOrder = () => {

    axiosInstance.post(`${proxy}/api/img/save-img-order`, images)
      .then(response => {
          showPopupSmall('Order saved successfully', 'success');
      })
      .catch(error => {
          showPopupSmall(`Error saving order: ${error}`, 'error');
      });
  };

const photoItems = images.filter(item => item.file_type === 'image');
console.log("photoItems", photoItems);
const videoItems = images.filter(item => item.file_type === 'video');


  const ListElement = ({ item }) => {
    const [hover, setHover] = useState(false);
    if (!item) return null; // Zabezpieczenie przed undefined item

    return (
        <>
            <div
                className="tiles-img-body"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{ position: "relative", width: tileWidth }}
            >
                {item.file_type === "image" && (
                    <>
                        <img
                            src={dynamicUrl + item.relative_file_path}
                            alt={item.filename}
                            width={tileWidth}
                            height="auto"
                            className={"tiles-img-img"}
                            style={{ opacity: hover ? 0.5 : 1 }}
                        />
                        {hover && (
                            <div className="tiles-img-hover">
                                <p>{item.relative_file_path}</p>
                                <div onClick={() => handleDelete(item.filename)}>
                                    <ButtonDelete />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {item.file_type === "video" && (
                    <>
                        <video width={tileWidth} controls>
                        <source src={serverUrl + item.relative_file_path} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {hover && (
                            <div className="tiles-img-hover">
                                <p>{item.relative_file_path}</p>
                                <div onClick={() => handleDelete(item.filename)}>
                                    <ButtonDelete />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <p style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
                    {item.filename}
                </p>
            </div>
        </>
    );
};

const PhotoTile = ({ item, index, items }) => {
  if (!item) return null; // Zabezpieczenie przed undefined item

  return (
      <div className="show-vis-tiles-img-body">
          {item.file_type === 'image' ? (
              <img
                  src={item.src}
                  alt={item.filename}
                  className="show-vis-tiles-img"
              />
          ) : (
              <video width={tileWidth} className="show-vis-tiles-img">
                  <source src={serverUrl + item.relative_file_path} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          )}
          <p style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
              {item.filename}
          </p>
      </div>
  );
};



return (
    <>
          
        <div style={{ width: "100%" }}>
            <h2>Photos <span className='show-vis-text-small'>({photoItems.length})</span></h2>
            <div className={"button-save-spacing"}>
            <button className="button-saveOrder-save" onClick={() => saveOrder()}>
                SAVE ORDER
            </button>
        </div>
            <ListManager
                items={photoItems}
                direction="horizontal"
                maxItems={5}
                render={(item) => <ListElement item={item} />}
                onDragEnd={onDragEnd}
                style={{ display: "flex", overflowX: "scroll", padding: "10px 0" }}
            />
        </div>
        <div style={{ width: "100%", marginTop: "50px" }}>
           {videoItems.length > 0 && (
                <>
                    <div className="show-vis-header" style={{ marginTop: "50px" }}>Videos <span className='show-vis-text-small'>({videoItems.length })</span></div>
                    <div className="show-vis-tiles-show-container">
                        {videoItems.map((image, index) => (
                            <PhotoTile key={image.id} item={image} index={index} items={videoItems} />
                        ))}
                    </div>
                </>
            )}
        </div>

        {showPopup && (
            <PopUpMessages
                show={showPopup} 
                message={`Do you really want to delete this image? It is linked with following SKUs: ${skus.map((sku) => sku.sku).join(", ")}`}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        )}
    </>
);
};

export default ProductImagesToSort;