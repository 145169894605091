import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import {Card} from 'react-bootstrap';
import axiosInstance from '../setup/axiosInstance';
import proxy from '../setup/proxy';

const GptBatchMonitor = ({refreshKey}) => {
  const [data, setData] = useState([]);
  const [ws, setWs] = useState(null);
  const [hiddenStatuses, setHiddenStatuses] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([
    'batch_name', 
    'created_at', 
    'status'
]); // Domyślnie widoczne kolumny

  // Pobierz dane z API
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`${proxy}/api/batch/batch-list/`);
      const sortedData = response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setData(sortedData);
    } catch (error) {
      console.error("Błąd przy pobieraniu danych z API", error);
    }
  };

  // Pobierz dane z API i posortuj je chronologicznie po created_at
  useEffect(() => {
    fetchData();
  }, [refreshKey]);



  
    // WebSocket do odbierania aktualizacji batcha
    useEffect(() => {
        const socket = new WebSocket('wss://compactorcloud.com:3001'); // Zamień na odpowiedni URL
    
        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          const { batch, status, success } = message; // Wyciągamy dane z komunikatu
    
          setData((prevData) =>
            prevData.map((item) =>
              item.batch_name === batch
                ? { ...item, status, success }
                : item
            )
          );
        };

    socket.onclose = () => {
      console.log("Połączenie WebSocket zamknięte");
    };

    socket.onerror = (error) => {
      console.error("Błąd WebSocket:", error);
    };

    return () => socket.close();
  }, []);

  // Ustal dostępne statusy na podstawie danych
  const availableStatuses = useMemo(() => {
    return [...new Set(data.map(item => item.status))];
  }, [data]);

  // Obsługa ukrywania statusów
  const toggleStatusFilter = (status) => {
    setHiddenStatuses((prev) => 
      prev.includes(status) 
        ? prev.filter(s => s !== status) 
        : [...prev, status]
    );
  };

  // Filtrowanie danych na podstawie ukrytych statusów
  const filteredData = useMemo(() => {
    return data.filter(item => !hiddenStatuses.includes(item.status));
  }, [data, hiddenStatuses]);

  // Definicja kolumn na podstawie visibleColumns
  const columns = useMemo(() => {
    return visibleColumns.map((key) => ({
      Header: key.charAt(0).toUpperCase() + key.slice(1), // Nazwa kolumny
      accessor: key, // Klucz kolumny
    }));
  }, [visibleColumns]);

  // Inicjalizacja tabeli z sortowaniem i paginacją
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageSize: 15, pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <Card title="Batch Monitor">
        <div>
            {/* button to run batch processor */}   
            <button onClick={() => axiosInstance.get(`${proxy}/api/batch/run-batch-worker/`)}>
                Run Batch processor
            </button>
        </div>

      <div>
        {/* Filtrowanie statusów */}
        <div className="status-filter">
          <span>Ukryj statusy: </span>
          {availableStatuses.map(status => (
            <label key={status}>
              <input
                type="checkbox"
                checked={hiddenStatuses.includes(status)}
                onChange={() => toggleStatusFilter(status)}
              />
              {status}
            </label>
          ))}
        </div>

        {/* Tabela */}
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Nawigacja paginacji */}
        <div className="pagination">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Poprzednia
          </button>
          <span>
            Strona{' '}
            <strong>
              {pageIndex + 1}
            </strong>
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Następna
          </button>
        </div>
      </div>
    </Card>
  );
};

export default GptBatchMonitor;