import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../setup/axiosInstance.js';
import UseWindowSize from './UseWindowSize.js';
import VisualBrowser from './VisualBrowser';
import './ShowProductVisuals.css';
import proxy from '../setup/proxy';
import { CategoryDivider } from "../components/visual/headers.js";
import { DownloadButton } from "./Buttons";

const ShowProductImagesWithResize = ({ selectedSKU, showResize = true, defaultSize = 0, showDownloadButton = false}) => {
    const [images, setImages] = useState([]);
    const [imgSize, setImgSize] = useState(600);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false); 
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentItems, setCurrentItems] = useState([]); // To track current items being viewed in the overlay

    if(defaultSize > 0) {
        setImgSize(defaultSize);
    }

    let dynamicUrl = proxy + `/img/dynamic/${imgSize}/`;
    let tumbnailUrl = proxy + `/img/dynamic/250/`;
    let { width } = UseWindowSize();
    let dragWidth = (Math.floor(width / 12) * 8) - 50;
    let tileWidth = Math.floor(dragWidth / 5);

    const fetchImages = useCallback(async () => {
        if (selectedSKU) {
            try {
                const response = await axiosInstance.get(`${proxy}/img/get-all/${selectedSKU}`);
                const sortedImages = response.data.sort((a, b) => a.visual_order - b.visual_order);
                setImages(sortedImages.map(image => ({
                    ...image,
                    src: image.file_type === 'image' ? dynamicUrl + image.relative_file_path : proxy + `/content/` + image.relative_file_path,
                    thumbnail: image.file_type === 'image' ? tumbnailUrl + image.relative_file_path : proxy + `/thumbnails/` + image.relative_file_path.replace('.mp4', '.jpg')
                })));
            } catch (error) {
                console.error('Error fetching images:', error);
                setImages([]);
            }
        }
    }, [selectedSKU, dynamicUrl, proxy, imgSize]);

    useEffect(() => {
        fetchImages();
    }, [fetchImages]);

    const handleSetImgSize = () => {
        dynamicUrl = `${proxy}/img/dynamic/${imgSize}x${imgSize}/`;
    };

    const openOverlay = (index, items) => {
        setCurrentIndex(index);
        setCurrentItems(items);
        setIsOverlayVisible(true);
    };

    const closeOverlay = () => {
        setIsOverlayVisible(false);
    };

    const PhotoTile = ({ item, index, items }) => {
        if (!item) return null; // Zabezpieczenie przed undefined item
    
        return (
            <div className="show-vis-tiles-img-body" onClick={() => openOverlay(index, items)}>
                {item.file_type === 'image' ? (
                    <img
                        src={item.src}
                        alt={item.filename}
                        className="show-vis-tiles-img"
                    />
                ) : (
                    <video width={tileWidth} className="show-vis-tiles-img">
                        <source src={item.src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                <p style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
                    {item.filename}
                </p>
            </div>
        );
    };

    const photoItems = images.filter(item => item.file_type === 'image');
    const videoItems = images.filter(item => item.file_type === 'video');

    return (
        <>
            {showResize && (
            <>
                <span>Choose image size in px: </span>
                <select
                    className="tiles-show-size-input"
                    defaultValue="600"
                    onChange={(e) => setImgSize(e.target.value)}
                >
                    <option value="250">250 px</option>
                    <option value="400">400 px</option>
                    <option value="600">600 px</option>
                    <option value="0">Original</option>
                </select>
            </>
            )}

            {photoItems.length > 0 && (
                <>
                    {/* <div className="show-vis-header">Photos <span className='show-vis-text-small'>({photoItems.length })</span></div> */}
                        <CategoryDivider category="Photos" additional_text={`(${photoItems.length}) `}/>
                      {showDownloadButton && <div className="d-flex d-100 justify-content-center mb-4">
                       <DownloadButton text={'download_visual_content'} skus={[selectedSKU]} />
                        </div> }
                    <div className="show-vis-tiles-show-container">
                        {photoItems.map((image, index) => (
                            <PhotoTile key={image.id} item={image} index={index} items={photoItems} />
                        ))}
                    </div>
                </>
            )}

            {videoItems.length > 0 && (
                <>
                    {/* <div className="show-vis-header" style={{ marginTop: "50px" }}>Videos <span className='show-vis-text-small'>({videoItems.length })</span></div> */}
                    <CategoryDivider category="Video" additional_text={`(${videoItems.length}) `}/>
                    <div className="show-vis-tiles-show-container">
                        {videoItems.map((image, index) => (
                            <PhotoTile key={image.id} item={image} index={index} items={videoItems} />
                        ))}
                    </div>
                </>
            )}

            <VisualBrowser
                isVisible={isOverlayVisible}
                images={currentItems}
                currentIndex={currentIndex}
                onClose={closeOverlay}
            />
        </>
    );
};

export { ShowProductImagesWithResize };
